import React, { useState, useMemo, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext, Controller } from "react-hook-form";
import { IconButton, Tooltip, Grid, Typography, Autocomplete, Alert, FormControlLabel, Checkbox } from "@mui/material";
import useStyles from "../styles";

//Components
import { getFormattedDate, getFormattedTime, getCustomDay } from "../../../utils/GetFormattedDateTimeDay";
import { IncidentLocationMap } from "../../Maps/IncidentLocationMap";
import CloseIncidentDialog from "../../Dialog/CloseIncidentDialog";
import SectionTitle from "../../Titles/SectionTitle";

//Icons
import {
    AlarmAddOutlined as SetTimeIcon
} from "@mui/icons-material";

//Context
import { usePatrollerData } from "../../../context/PatrollerContext";

//theme
import themes from "../../../theme/defaultTheme";

// local database
import { deleteRequest, saveRequest } from "../../../db";

export const TimeLocationForm = ({ existingIncidentData }) => {
    const { control, watch, setValue, getValues, formState: { errors } } = useFormContext();
    const { StyledMandatoryGrid, FormLabel, StyledTextField, StyledInputRequired, AdditionalInfoLabel } = useStyles();
    const navigate = useNavigate();
    const currentDate = new Date();
    const formattedDate = getFormattedDate(currentDate);
    const currentDay = getCustomDay(currentDate);
    const [currentTime, setCurrentTime] = useState('');
    const selectedRoute = watch('location.route');
    const selectedFreeway = watch('location.freeway');
    const currentLatitude = watch('location.latitude');
    const currentLongitude = watch('location.longitude');
    const [showAlert] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { updatePatrollerData, location, incidentDrpDwnValues, routeRelatedDrpDwnVal } = usePatrollerData();

    useLayoutEffect(() => {
        const formDetectMethod = getValues()?.time?.detectMethod || null;

        //set default value of mobile patrol if no value present
        if (!formDetectMethod)
            setValue("time.detectMethod", "Mobile Patrol");
    }, [])

    const handleSetStartTimeBtn = () => {
        const currentDate = new Date();
        const currentTime = getFormattedTime(currentDate);
        setCurrentTime(currentTime);

        //Set form state
        setValue("time.startTime", currentDate);
        saveRequest("/patroller/postAddIncident",
            getValues(),
            "pi0",
            "tempFormData");
    }

    const handleRouteChange = (event) => {

        // Clear the 'freeway' field
        setValue('location.freeway', null);
        setValue('location.crossStreet', null);

        // Update the 'route' field
        setValue('location.route', event.target.value);
    };

    const handleFreewayChange = (event) => {

        // Clear the 'cross street' field
        setValue('location.crossStreet', null);

        // Update the 'freeway' field
        setValue('location.freeway', event.target.value);
    };

    const handleSubmitDialog = async (comments) => {
        let mode = "add"
        setIsDialogOpen(false);
        const newIncident = {
            shiftId: JSON.parse(sessionStorage.getItem("user"))?.shift?.id || null,
            closingComments: comments,
            status: "closed",
            time: getValues()?.time || {},
            location: getValues()?.location || {},
            vehicles: getValues()?.vehicles || [],
            images: getValues()?.images || [],
            isCritical: getValues()?.isCritical || false
        }

        //for edit
        if (existingIncidentData && Object.keys(existingIncidentData)?.length > 0 && existingIncidentData.id) {
            newIncident.id = existingIncidentData.id;
            mode = "edit";
        }
        await updatePatrollerData(newIncident, mode)

        // Delete temp form data on submission for IndexedDB storage
        deleteRequest({ id: "pi0" }, "tempFormData")

        //Navigate to view open incidents page.
        navigate('/patroller/view-open-incidents');
    };

    useEffect(() => {
        if (!currentLatitude && !currentLongitude && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setValue('location.latitude', latitude);
                    setValue('location.longitude', longitude);
                    saveRequest(
                        "/patroller/postAddIncident",
                        getValues(),
                        "pi0",
                        "tempFormData"
                    );
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        }
    }, [currentLatitude, currentLongitude]);

    useLayoutEffect(() => {
        const startTimeValue = getValues('time.startTime');
        if (startTimeValue)
            setCurrentTime(getFormattedTime(startTimeValue));
        else
            setCurrentTime('');
    }, []);

    //drop down values
    const detectMethodOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues)?.length > 0)
            return incidentDrpDwnValues.detectMethods || [];
    }, [incidentDrpDwnValues]);

    const routeOptions = useMemo(() => {
        return routeRelatedDrpDwnVal?.length > 0 ? routeRelatedDrpDwnVal.map(route => ({
            id: route.id,
            name: route.name
        })) : [];
    }, [routeRelatedDrpDwnVal]);

    const freewayOptions = useMemo(() => {
        if (selectedRoute && routeRelatedDrpDwnVal?.length > 0) {
            const selectedRouteData = routeRelatedDrpDwnVal.find(route => route.id === selectedRoute.id);
            return selectedRouteData?.freeways?.map(fwy => ({
                id: fwy.id,
                name: fwy.name
            })) || [];
        }
        return [];
    }, [selectedRoute, routeRelatedDrpDwnVal]);

    const crossStreetOptions = useMemo(() => {
        if (selectedRoute && selectedFreeway && routeRelatedDrpDwnVal?.length > 0) {
            const selectedRouteData = routeRelatedDrpDwnVal.find(route => route.id === selectedRoute.id);
            const selectedFreewayData = selectedRouteData?.freeways.find(fwy => fwy.id === selectedFreeway.id);
            return selectedFreewayData?.crossStreets?.map(street => ({
                id: street.id,
                name: street.name
            })) || [];
        }
        return [];
    }, [selectedRoute, selectedFreeway, routeRelatedDrpDwnVal]);

    const lanesBlockedOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.lanesBlocked || [];
    }, [incidentDrpDwnValues]);

    const travelDirectionOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.travelDirections || [];
    }, [incidentDrpDwnValues]);

    return (
        <Grid container spacing={2}>

            {/* Alert to close an incident */}
            {showAlert && (
                <Grid item xs={12}>
                    <Alert severity="info" onClick={() => { setIsDialogOpen(true) }} style={{ cursor: "pointer", marginLeft: "17px" }}>Click here to CLOSE incident.</Alert>
                </Grid>
            )}

            {/* Mandatory fields label */}
            <StyledMandatoryGrid item xs={12}>
                <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} color="inherit" variant="caption">All <span style={{ color: 'red' }}>*</span> marked fields are required.</Typography>
            </StyledMandatoryGrid>

            <Grid item xs={12}>
                <SectionTitle title="Time" />
            </Grid>

            <Grid item xs={12}>
                <form>
                    <Grid container spacing={4}>

                        {/* Date Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel >Date</FormLabel>
                        </Grid>

                        {/* Date Field */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="time.date"
                                control={control}
                                defaultValue={formattedDate}
                                render={({ field }) => (
                                    <StyledTextField {...field} disabled />
                                )}
                            />
                        </Grid>

                        {/* Start Time Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Start Time<em style={{ color: 'red' }}>*</em></FormLabel>
                            {errors?.time?.startTime && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Start Time Field */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Controller
                                        name="time.startTime"
                                        control={control}
                                        defaultValue={currentTime || currentDate}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <StyledTextField {...field} error={!!errors?.time?.startTime} value={currentTime || getFormattedTime(currentDate)} sx={{ width: "100%" }} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Tooltip title="Click to set time">
                                        <IconButton onClick={handleSetStartTimeBtn}>
                                            <SetTimeIcon
                                                sx={{
                                                    color: themes.default.palette.primary.contrastText,
                                                    marginTop: "6px",
                                                    paddingLeft: 2
                                                }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Day of week Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Day of week</FormLabel>
                        </Grid>

                        {/* Day of Week field */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="time.dayOfWeek"
                                control={control}
                                defaultValue={currentDay}
                                render={({ field }) => (
                                    <StyledTextField {...field} disabled />
                                )}
                            />
                        </Grid>

                        {/* Detect Method label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Detect Method<em style={{ color: 'red' }}>*</em></FormLabel>
                            {errors?.time?.detectMethod && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Detect Method drop down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="time.detectMethod"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        value={field.value || null}
                                        options={detectMethodOptions || []}
                                        getOptionLabel={(option) => option || ""}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        onChange={(_, value) => { field.onChange(value); saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData") }}
                                        renderInput={(params) => <StyledTextField {...params} error={!!errors?.time?.detectMethod} label="Select detect method" />}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Mark as critical */}
                        <Grid item xs={6} sm={4} md={4} lg={4} sx={{ marginLeft: "17px" }}>
                            <Controller
                                name="isCritical"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e.target.checked);
                                                    saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData")
                                                }}
                                            />
                                        }
                                        label="Mark incident as critical"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SectionTitle
                                title="Location"
                            />
                        </Grid>

                        {/* Route Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Route<em style={{ color: 'red' }}>*</em></FormLabel>
                            {errors?.location?.route && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Route drop down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.route"
                                defaultValue={location.route || null}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        value={field.value || null}
                                        options={routeOptions || []}
                                        getOptionLabel={(option) => option?.name || ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, data) => {
                                            handleRouteChange(event);
                                            field.onChange(data);
                                            saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData");
                                        }}
                                        renderInput={(params) => <StyledTextField {...params} error={!!errors?.location?.route} label="Select route" />}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Travel Direction label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Travel Direction<em style={{ color: 'red' }}>*</em></FormLabel>
                            <AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>
                            {errors?.location?.travelDirection && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Travel Direction multi select drop down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.travelDirection"
                                defaultValue={location.travelDirection || []}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        value={field.value || []}
                                        multiple
                                        options={travelDirectionOptions || []}
                                        getOptionLabel={(option) => option}
                                        onChange={(_, data) => { field.onChange(data); saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData"); }}
                                        renderInput={(params) => <StyledTextField {...params} error={!!errors?.location?.travelDirection} label="Select travel direction(s)" />}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Freeway Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Freeway<em style={{ color: 'red' }}>*</em></FormLabel>
                            {errors?.location?.freeway && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Freeway drop down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.freeway"
                                defaultValue={location.freeway || null}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        value={field.value || null}
                                        options={freewayOptions || []}
                                        getOptionLabel={(option) => option?.name || ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, data) => {
                                            handleFreewayChange(event);
                                            field.onChange(data);
                                            saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData");
                                        }}
                                        renderInput={(params) => <StyledTextField {...params} error={!!errors?.location?.freeway} label="Select freeway" />}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Cross Street Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Cross Street<em style={{ color: 'red' }}>*</em></FormLabel>
                            {errors?.location?.crossStreet && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Cross Street drop down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.crossStreet"
                                defaultValue={location.crossStreet || null}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        value={field.value || null}
                                        options={crossStreetOptions || []}
                                        getOptionLabel={(option) => option?.name || ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(_, data) => { field.onChange(data); saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData"); }}
                                        renderInput={(params) => <StyledTextField {...params} error={!!errors?.location?.crossStreet} label="Select cross street" />}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Lanes Blocked label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Lane(s) Blocked<em style={{ color: 'red' }}>*</em></FormLabel>
                            <AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>
                            {errors?.location?.lanesBlocked && (
                                <StyledInputRequired>Required field</StyledInputRequired>
                            )}
                        </Grid>

                        {/* Lanes Blocked multi select drop down */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.lanesBlocked"
                                defaultValue={location.lanesBlocked || []}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        value={field.value || []}
                                        multiple
                                        options={lanesBlockedOptions || []}
                                        getOptionLabel={(option) => option?.name || ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(_, data) => { field.onChange(data); saveRequest("/patroller/postAddIncident", getValues(), "pi0", "tempFormData"); }}
                                        renderInput={(params) => <StyledTextField {...params} error={!!errors?.location?.lanesBlocked} label="Select lane(s) blocked" />}
                                    />
                                )}
                            />
                        </Grid>

                        {/* Empty Grid item to push the next items to the next line */}
                        <Grid item xs={12} sm={6} md={6} lg={6} />

                        {/* Latitude Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Latitude</FormLabel>
                        </Grid>

                        {/* Latitude Field */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.latitude"
                                control={control}
                                render={({ field }) => (
                                    <StyledTextField {...field} disabled />
                                )}
                            />
                        </Grid>

                        {/* Longitude Label */}
                        <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Longitude</FormLabel>
                        </Grid>

                        {/* Longitude Field */}
                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                                name="location.longitude"
                                control={control}
                                render={({ field }) => (
                                    <StyledTextField {...field} disabled />
                                )}
                            />
                        </Grid>

                        {/* Map */}
                        <Grid item xs={12}>
                            <IncidentLocationMap
                                latitude={getValues()?.location?.latitude || ''}
                                longitude={getValues()?.location?.longitude || ''}
                                setValue={setValue} />
                        </Grid>
                    </Grid>
                </form>
                <CloseIncidentDialog
                    open={isDialogOpen}
                    onClose={() => { setIsDialogOpen(false) }}
                    onSubmit={handleSubmitDialog}
                    title="Close Incident"
                />
            </Grid >
        </Grid >
    );
};